<template>
    <standard-page :title="step.name" v-if="step.name">
        <template v-if="currentRouteName === 'view-process-definition-step-by-attestor'" v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'available-process'}">Available Processes</router-link>
      </span>
            <span v-if="processDefinition">
        <router-link :to="{ name: 'available-process'}">Process: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
            <span>Step: {{step.name | truncate(10, '.....')}}</span>
        </template>
        <template v-else v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
            <span v-if="registry">
        <router-link :title="registry.name" :to="{ name: 'processdefinitions'}">Registry: {{registry.name | truncate(10, '.....')}}</router-link>
      </span>
            <span v-if="processDefinition">
        <router-link :title="processDefinition.name" :to="{ name: 'process-definition-steps'}">Process Definition: {{processDefinition.name | truncate(10, '.....')}}</router-link>
      </span>
            <span>Step: {{step.name | truncate(10, '.....')}}</span>
        </template>
        <template v-slot:content>
            <div class="row row-sm">
                <div class="col-9">
                    <p v-html="$DEFINITIONS.provenance.processStep"></p>
                </div>
                <div class="col-3"  v-if="currentRouteName !== 'view-process-definition-step-by-attestor'">
                    <button @click="edit" class="d-none d-xl-block float-right btn btn-orange rounded font-weight-bold" title="Add Process Definition">
                        <i class="fas fa-pen-nib mg-r-10"/>Edit
                    </button>
                    <button @click="edit" class="d-xl-none btn btn-icon float-right w-35 btn-orange rounded" title="Add Process Definition">
                        <i class="typcn typcn-edit"/>
                    </button>
                </div>
            </div>
            <div class="az-content-body az-content-body-profile">
                <nav class="nav az-nav-line">
                    <a :class="{active: activeTab === 0}" @click="basicDetails" class="nav-link" data-toggle="tab" href="#basic">Basic Details</a>
                    <a :class="{active: activeTab === 1}" @click="defAttribute" class="nav-link" data-toggle="tab" href="#defAttribute">Definition Attributes</a>
                    <a :class="{active: activeTab === 2}" @click="attesters" class="nav-link" data-toggle="tab" href="#attesters">Attesters</a>
                </nav>
                <div class="az-tab-content">
                    <div :class="{'d-none': activeTab !== 0}" id="basic">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-xl-12">
                                <div class="mg-t-20">
                                    <div class="form-group">
                                        <label class="az-content-label tx-11 tx-medium tx-gray-600">Name</label>
                                        <input class="form-control" readonly required="" type="text" v-model="step.name">
                                    </div><!-- form-group -->

                                    <div class="form-group">
                                        <label class="az-content-label tx-11 tx-medium tx-gray-600">Description</label>
                                        <div class="pos-relative">
                                            <textarea class="form-control pd-r-80" disabled required="" type="text" v-if="step.description" v-model="step.description"></textarea>
                                            <textarea class="form-control pd-r-80" disabled required="" type="text" v-else>NA</textarea>
                                        </div>
                                    </div><!-- form-group -->
                                </div><!-- card -->
                            </div>
                        </div>
                    </div><!-- az-profile-body -->
                    <div :class="{'d-none': activeTab !== 1}" id="defAttribute">
                        <div class="mg-t-20">
                            <data-table :api="api" :columns="columns" :server-side="true" @ready="onReady" list-key="process_definition_step_attributes" table-id="attribute_table"
                                        total-key="total"></data-table>
                        </div>
                    </div><!-- az-profile-body -->
                    <div :class="{'d-none': activeTab !== 2}" id="attesters">
                        <div class="row row-sm mg-t-20">
                            <div class="col-9">
                                <p v-html="$DEFINITIONS.provenance.attestor"></p>
                            </div>
                            <div class="col-3" v-if="currentRouteName !== 'view-process-definition-step-by-attestor'">
                                <button @click="addAttestor" class="d-none d-xl-block float-right btn btn-outline-indigo rounded font-weight-bold" title="Add Attester">
                                    <i class="fas fa-plus-square mg-r-10"/>Add
                                </button>
                                <button @click="addAttestor" class="d-xl-none w-35 float-right btn btn-icon btn-orange rounded" title="Add Attester">
                                    <i class="typcn typcn-document-add"/>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive" v-if="step.attestors.length>0">
                            <table class="table table-bordered mg-b-0">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Short Name</th>
                                    <th>DID</th>
                                    <th v-if="processDefinition.status !== 'InActive' && currentRouteName !== 'view-process-definition-step-by-attestor'">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-bind:key="index" v-for="(attestor, index) in step.attestors">
                                    <th scope="row">{{index+1}}</th>
                                    <td>{{attestor.short_name}}</td>
                                    <td>{{attestor.did}}</td>
                                    <td class="action" v-if="processDefinition.status !== 'InActive' && currentRouteName !== 'view-process-definition-step-by-attestor'">
                            <span @click="setDidToRemove(attestor.did, attestor.short_name)" class="text-danger" data-target="#confirmationmodal" data-toggle="modal" style="cursor:pointer;"
                                  title="Delete Attestor">
                              <i class="fas fa-trash-alt fa-lg"/>
                            </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mg-t-50" v-else>
                            <h4 class="card-title text-muted text-center">No records found</h4>
                        </div>
                        <div class="modal" id="confirmationmodal">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content modal-content-demo">
                                    <div class="modal-body tx-center pd-y-20 pd-x-20">
                                        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <i class="icon ion-ios-alert tx-100 tx-indigo lh-1 mg-t-20 d-inline-block"></i>
                                        <h4 class="tx-indigo mg-b-20">Warning!</h4>
                                        <p class="mg-b-20 mg-x-20">Are you sure you want to delete this attestor?</p>
                                        <button @click="removeAttestor" class="btn rounded btn-az-primary pd-x-25" data-dismiss="modal" type="button">Continue</button>
                                    </div>
                                </div><!-- modal-content -->
                            </div><!-- modal-dialog -->
                        </div>
                    </div><!-- az-profile-body -->
                </div>
            </div>
        </template>
    </standard-page>
</template>

<script>
    import EventBus from "@/event-bus";

    export default {
        name: "ViewProcessDefinition", computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        props: ['registryid', 'stepid', 'processdefinitionid'],
        data() {
            return {
                registry: null,
                processDefinition: null,
                step: {
                    name: null,
                    desc: null,
                    attestors: []
                },
                attestor_to_remove: [],
                api: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}/attributes`,
                columns: [
                    {type: 'name'},
                    {
                        title: 'Type',
                        data: 'fact_type',
                    },
                    {
                        title: 'Required',
                        data: 'required',
                    },
                    {
                        title: 'Options',
                        data: 'options',
                        render: data => data ? data : ""
                    },
                    {
                        title: 'Group',
                        data: 'attribute_group',
                    },
                    {
                        title: 'Repeatable',
                        data: 'repeatable',
                    },
                    {
                        title: 'Private',
                        data: 'private',
                    },

                    {type: 'created'},
                ],
                table: null,
                activeTab: 0,
            };
        },
        mounted() {
            console.log(this.currentRouteName);
            this.init();

        },

        methods: {
            onReady(table) {
                this.table = table;
            },
            async init() {
                await this.getRegistry();
                await this.getProcessDefinition();
                await this.getProcessDefinitionStep();
            },
            async getRegistry() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}`);
                    this.registry = data;
                } catch (e) {
                } finally {

                }
            },
            async getProcessDefinition() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
                    this.processDefinition = data;
                } catch (e) {
                    this.$toastr.e("Error retrieving Process Definition", "Error");
                } finally {

                }
            },
            async getProcessDefinitionStep() {
                try {
                    let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}`);
                    this.step = data;
                } catch (e) {

                }
            },
            setDidToRemove(did, short_name) {
                let _attestor = {
                    did: did,
                    short_name: short_name
                };
                this.attestor_to_remove.push(_attestor);
            },
            async removeAttestor() {
                try {
                    EventBus.$emit('openLoader');
                    await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}`, {
                        name: this.step.name,
                        remove_attestors: this.attestor_to_remove
                    });
                    this.$toastr.s("Attestor Successfully removed!", 'Success');
                } catch (e) {
                    this.$toastr.e("Attestor deletion failed", 'Error');
                } finally {
                    this.attestor_to_remove = [];
                    EventBus.$emit('closeLoader');
                    await this.getProcessDefinitionStep();
                }
            },
            edit() {
                this.$router.push({name: 'edit-process-definition-step', params: {stepid: this.stepid}});
            },
            addAttestor() {
                this.$router.push({name: 'add-attestor'});
            },
            basicDetails() {
                this.activeTab = 0;
            },
            defAttribute() {
                this.activeTab = 1;
            },
            attesters() {
                this.activeTab = 2;
            },
        }
    }
</script>

<style scoped>
</style>